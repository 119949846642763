export var ANAF_SPV_RO_E_TRANSPORT_READ = "anaf-spv.ro-e-transport.read";
export var ANAF_SPV_RO_E_TRANSPORT_WRITE = "anaf-spv.ro-e-transport.write";
export var ANAF_SPV_RO_E_TRANSPORT_DELETE = "anaf-spv.ro-e-transport.delete";
export var INVENTORY_WAREHOUSES_READ = "warehouses.read";
export var INVENTORY_WAREHOUSES_WRITE = "warehouses.write";
export var INVENTORY_WAREHOUSES_DELETE = "warehouses.delete";
export var INVENTORY_GOODS_RECEIPT_NOTES_READ = "goods-receipts-notes.read";
export var INVENTORY_GOODS_RECEIPT_NOTES_WRITE = "goods-receipts-notes.write";
export var INVENTORY_GOODS_RECEIPT_NOTES_DELETE = "goods-receipts-notes.delete";
export var INVENTORY_STOCK_CONSUMPTION_RECEIPTS_READ = "stock-consumption-receipts.read";
export var INVENTORY_STOCK_CONSUMPTION_RECEIPTS_WRITE = "stock-consumption-receipts.write";
export var INVENTORY_STOCK_CONSUMPTION_RECEIPTS_DELETE = "stock-consumption-receipts.delete";
export var INVENTORY_STOCK_RECEPTION_RECEIPTS_READ = "stock-reception-receipts.read";
export var INVENTORY_STOCK_RECEPTION_RECEIPTS_WRITE = "stock-reception-receipts.write";
export var INVENTORY_STOCK_RECEPTION_RECEIPTS_DELETE = "stock-reception-receipts.delete";
export var INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_READ = "client-goods-despatch-advices.read";
export var INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_WRITE = "client-goods-despatch-advices.write";
export var INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_DELETE = "client-goods-despatch-advices.delete";
