// Functions used by NumberInput
function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_primitive(input, hint) {
    if (_type_of(input) !== "object" || input === null) return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || "default");
        if (_type_of(res) !== "object") return res;
        throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return (hint === "string" ? String : Number)(input);
}
function _to_property_key(arg) {
    var key = _to_primitive(arg, "string");
    return _type_of(key) === "symbol" ? key : String(key);
}
function _type_of(obj) {
    "@swc/helpers - typeof";
    return obj && typeof Symbol !== "undefined" && obj.constructor === Symbol ? "symbol" : typeof obj;
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { format as _format, startOfYear, endOfYear, addDays, setHours, setMinutes, setSeconds, setMilliseconds, startOfMonth, parse, subDays, parseISO, isValid } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import { hasLocalStorageEnabled, setHasLocalStorageEnabled } from "./ApplicationLoaderContext";
import { DEFAULT_NOTIFICATIONS_PER_PAGE, NOTIFICATIONS_FILTER_IS_READ } from "../notifications/utils";
import { notificationsActions } from "../../redux/notifications";
import handleReportError from "../errors/handleReportError";
import { redirect } from "react-router-dom";
import { authSelectors } from "../../redux/auth";
import { STANDARD_CODES } from "../products/utils";
import { getErrorMessageTranslated, httpStatusCodes, mapErrorByHttpStatusCode } from "../../hooks/useErrorMessage";
import Big from "big.js";
export var DEFAULT_VAT_RATE_VALUE = 19;
export var DEFAULT_VAT_RATE_FOR_NON_VAT_PAYER = "O";
export var DEFAULT_VAT_RATE = "S";
export var DEFAULT_DATE_DISPLAY = "PP";
export var DEFAULT_DATE_DISPLAY_WITH_TIME = "PP HH:mm";
export var DEFAULT_DATE_VALUE = "yyyy-MM-dd";
export var DEFAULT_DATE_VALUE_WITH_TIME = "yyyy-MM-dd HH:mm:ss";
export var DEFAULT_DATE_VALUE_WITH_TIME_WITHOUT_SECONDS = "yyyy-MM-dd HH:mm";
export var DATE_HOURS_MINUTES_FORMAT = "HH:mm";
export var DATE_HOURS_MINUTES_FORMAT_WITH_AM_OR_PM_SUFFIX = "hh:mm a";
export var DEFAULT_CURRENCY_CODE = "RON";
var euCountriesCodes = [
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE"
];
export var operators = {
    EQ: "eq",
    LT: "lt",
    LTE: "lte"
};
export var addressesTypes = {
    HEADQUARTERS: "headquarters"
};
export var GENERIC_STATES = {
    LOADING: "loading",
    IDLE: "idle"
};
export function getIsCountryFromEu(countryCode) {
    return !!euCountriesCodes.find(function(item) {
        return item === countryCode;
    });
}
export var EXPORTS_STATUSES = {
    SUCCESS: "success",
    PENDING: "pending",
    FAILED: "failed",
    PARTIALLY_FAILED: "partially-failed"
};
export var EXPORTS_TYPES = {
    CLIENT_INVOICE_PDFS: "client-invoice-pdfs",
    CLIENT_INVOICE_SEALS: "client-invoice-seals",
    SUPPLIER_INVOICE_PDFS: "supplier-invoice-pdfs",
    SUPPLIER_INVOICE_SEALS: "supplier-invoice-seals"
};
export var EFACTURA_CODES = {
    CLIENT_EXTERNAL_ID: "BT-10",
    CLIENT_ACCOUNTING_ID: "BT-19",
    PRODUCT_IDENTIFIER_CODE: "BT-18",
    CONTRACT_REFERENCE: "BT-12",
    PURCHASE_ORDER_REFERENCE: "BT-13",
    SALES_DISPOSITION_REFERENCE: "BT-17",
    SALES_ORDER_REFERENCE: "BT-14",
    RECEIVING_ADVICE_REFERENCE: "BT-15",
    DESPATCH_ADVICE_REFERENCE: "BT-16",
    PROJECT_REFERENCE: "BT-11",
    PRODUCT_CODE: "BT-155",
    EU_CN_CODE: "BT-158",
    CPV_CODE: "BT-158",
    CLIENT_PRODUCT_CODE: "BT-156",
    CLIENT_PRODUCT_ACCOUNTING_CODE: "BT-133",
    CLIENT_PRODUCT_IDENTIFIER_CODE: "BT-128",
    CLIENT_PRODUCT_STANDARD_CODE: "BT-157",
    SUPPLIER_PRODUCT_CODE: "BT-156",
    SUPPLIER_PRODUCT_ACCOUNTING_CODE: "BT-133",
    SUPPLIER_PRODUCT_IDENTIFIER_CODE: "BT-128",
    SUPPLIER_PRODUCT_STANDARD_CODE: "BT-157",
    CLIENT_INVOICE_NUMBER: "BT-1",
    INVOICE_ISSUE_DATE: "BT-2",
    INVOICE_EFACTURA_INVOICE_TYPE: "BT-3",
    INVOICE_DUE_DATE: "BT-9",
    INVOICE_CURRENCY_CODE: "BT-5",
    INVOICE_TAX_POINT_DATE: "BT-7",
    INVOICE_TAX_POINT_TYPE: "BT-8",
    INVOICE_PAYMENT_TERMS: "BT-20",
    INVOICE_ADDITIONAL_INFO: "BT-22",
    REFERENCE_INVOICE_SERIES_AND_NUMBER: "BT-25",
    REFERENCE_INVOICE_ISSUE_DATE: "BT-26",
    SELLER_NAME: "BT-27",
    SELLER_COMMERCIAL_NAME: "BT-28",
    SELLER_SECONDARY_BUSINESS_ID: "BT-29",
    SELLER_BUSINESS_ID: "BT-30",
    SELLER_BUSINESS_ID2: "BT-31",
    SELLER_RO_BUSINESS_ID: "BT-32",
    SELLER_INTRA_COMMUNITY_VAT_NUMBER: "BT-31",
    SELLER_EXTRA_INFO: "BT-33",
    SELLER_ADDRESS_STREET_NAME_AND_NUMBER: "BT-35",
    SELLER_ADDRESS_STREET_ADDITIONAL_DETAILS: "BT-36",
    SELLER_ADDRESS_ADDITIONAL_DETAILS: "BT-162",
    SELLER_ADDRESS_CITY: "BT-37",
    SELLER_ADDRESS_ZIP_CODE: "BT-38",
    SELLER_ADDRESS_COUNTRY_SUBDIV: "BT-39",
    SELLER_ADDRESS_COUNTRY: "BT-40",
    SELLER_CONTACT_NAME: "BT-41",
    SELLER_CONTACT_PHONE_NUMBER: "BT-42",
    SELLER_CONTACT_EMAIL_ADDRESS: "BT-43",
    BUYER_NAME: "BT-44",
    BUYER_COMMERCIAL_NAME: "BT-45",
    BUYER_SECONDARY_BUSINESS_ID: "BT-46",
    BUYER_BUSINESS_ID: "BT-47",
    BUYER_BUSINESS_ID2: "BT-48",
    BUYER_ADDRESS_STREET_NAME_AND_NUMBER: "BT-50",
    BUYER_ADDRESS_STREET_ADDITIONAL_DETAILS: "BT-51",
    BUYER_ADDRESS_ADDITIONAL_DETAILS: "BT-163",
    BUYER_ADDRESS_CITY: "BT-52",
    BUYER_ADDRESS_ZIP_CODE: "BT-53",
    BUYER_ADDRESS_COUNTRY_SUBDIV: "BT-54",
    BUYER_ADDRESS_COUNTRY: "BT-55",
    BUYER_CONTACT_NAME: "BT-56",
    BUYER_CONTACT_PHONE_NUMBER: "BT-57",
    BUYER_CONTACT_EMAIL_ADDRESS: "BT-58",
    INVOICE_BENEFICIARY_DETAILS: "BT-59",
    INVOICE_BENEFICIARY_IDENTIFIER: "BT-60",
    INVOICE_BENEFICIARY_IDENTIFIER2: "BT-61",
    BUYER_DELIVERY_PERSON: "BT-70",
    BUYER_DELIVERY_ADDRESS_NAME: "BT-71",
    INVOICE_REAL_DELIVERY_DATE: "BT-72",
    INVOICE_BILLING_START_DATE: "BT-73",
    INVOICE_BILLING_END_DATE: "BT-74",
    BUYER_DELIVERY_ADDRESS_NAME_AND_NUMBER: "BT-75",
    BANK_ACCOUNT_IDENTIFIER: "BT-84",
    BANK_ACCOUNT_NAME: "BT-85",
    GLOBAL_BANK_NAME: "BT-85",
    GLOBAL_BANK_SWIFT_CODE: "BT-86",
    // INVOICE_SUBTOTAL: "BT-109",
    // INVOICE_VAT_TOTAL: "BT-110",
    // INVOICE_TOTAL_WITH_VAT: "BT-115",
    // INVOICE_VAT_FOR_EACH_VAT_CATEGORY: "BT-117",
    VAT_CATEGORY_CODE: "BT-118",
    VAT_RATE: "BT-119",
    VAT_EXEMPTION_REASON_CODE: "BT-121",
    SUPPLIER_INVOICE_NOTIFICATION_MESSAGE: "BT-122",
    INVOICE_LINE_QUANTITY: "BT-129",
    INVOICE_LINE_MEASUREMENT_UNIT_RATIO: "BT-149",
    INVOICE_LINE_CODE_OF_UNIT_OF_MEASURE_OF_QUANTITY: "BT-130",
    INVOICE_LINE_SUBTOTAL: "BT-131",
    INVOICE_LINE_BILLING_PERIOD_START: "BT-134",
    INVOICE_LINE_BILLING_PERIOD_END: "BT-135",
    // INVOICE_LINE_DISCOUNT_SUBTOTAL: "BT-136",
    // INVOICE_LINE_BASE_DISCOUNT_WHEN_PERCENTAGE: "BT-137", //TODO -> Check EXCEL again, not clear
    INVOICE_LINE_DISCOUNT_PERCENTAGE: "BT-138",
    // INVOICE_LINE_NET_PRICE_WITHOUT_PRICE_REDUCTION: "BT-146", //TODO -> Check EXCEL again, not clear
    INVOICE_LINE_DISCOUNT_PRICE_REDUCTION: "BT-147",
    INVOICE_LINE_PRODUCT_NAME: "BT-153",
    INVOICE_LINE_PRODUCT_DESCRIPTION: "BT-154",
    PRODUCT_ATTRIBUTE_NAME: "BT-160",
    PRODUCT_ATTRIBUTE_VALUE: "BT-161"
};
export function getStandardCodesTypes(translate) {
    return [
        {
            label: translate("common_standard_code_type_0010"),
            value: STANDARD_CODES.OISN_9541_PART2
        },
        {
            label: translate("common_standard_code_type_0011"),
            value: STANDARD_CODES.CODE_DESIGNER_OSI_AMATEUR_RADIO
        },
        {
            label: translate("common_standard_code_type_0012"),
            value: STANDARD_CODES.ECMA
        },
        {
            label: translate("common_standard_code_type_0016"),
            value: STANDARD_CODES.EWOS
        },
        {
            label: translate("common_standard_code_type_0021"),
            value: STANDARD_CODES.SWIFT
        },
        {
            label: translate("common_standard_code_type_0028"),
            value: STANDARD_CODES.ISO_6523
        },
        {
            label: translate("common_standard_code_type_0064"),
            value: STANDARD_CODES.UTC
        },
        {
            label: translate("common_standard_code_type_0073"),
            value: STANDARD_CODES.ICD_FORMATTED
        },
        {
            label: translate("common_standard_code_type_0088"),
            value: STANDARD_CODES.EAN_LOCATION
        },
        {
            label: translate("common_standard_code_type_0090"),
            value: STANDARD_CODES.INTERNET_ISO_6523_ICD_ENCODING
        },
        {
            label: translate("common_standard_code_type_0091"),
            value: STANDARD_CODES.CISCO_OSI_NETWORK
        },
        {
            label: translate("common_standard_code_type_0112"),
            value: STANDARD_CODES.ISO_REGISTER_STANDARD_PRODUCING_ORG
        },
        {
            label: translate("common_standard_code_type_0126"),
            value: STANDARD_CODES.GTE_OSI_NETWORK
        },
        {
            label: translate("common_standard_code_type_0149"),
            value: STANDARD_CODES.GLOBAL_BUSINESS_IDENTIFIER
        },
        {
            label: translate("common_standard_code_type_0160"),
            value: STANDARD_CODES.GTIN
        }
    ];
}
export function joinBtCodes(codes) {
    return codes.join(", ");
}
export function undot(objectWithDottedStrings) {
    return Object.entries(objectWithDottedStrings).reduce(function(r, param) {
        var _param = _sliced_to_array(param, 2), k = _param[0], v = _param[1];
        k.split(".").reduce(function(a, e, i, ar) {
            return a[e] || (a[e] = ar[i + 1] ? {} : v);
        }, r);
        return r;
    }, {});
}
//Restrict spaces on change
export function restrictSpaces(e, handleChange) {
    var value = e.target.value;
    if (value.includes(" ")) {
        value = value.replaceAll(" ", "");
        e.target.value = value;
    }
    return handleChange(e);
}
// Ensure we don't lose the filters after filter/search - needed in Selectors that appear in filters (if  value should be integer)
export function ensureFiltersWork(value) {
    //eventual o sa dispara functia asta ca o sa facem parseInt unde avem value string (cand primim filtrele de ex)
    //momentan trebuie folosita la filtrele unde value trebuie sa fie number
    return value !== "" && typeof value === "string" ? parseInt(value) : value;
}
//? If you use any of these for a request maybe create a const before the dispatch to ensure it's the same value everywhere
export var getFirstDateOfCurrentYear = function() {
    return _format(startOfYear(new Date()), DEFAULT_DATE_VALUE);
};
export var getFirstDateOfCurrentMonth = function() {
    return _format(startOfMonth(new Date()), DEFAULT_DATE_VALUE);
};
export var getCurrentDate = function() {
    return setHours(setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0), 0);
};
export var getCurrentDateFormatted = function() {
    return _format(getCurrentDate(), DEFAULT_DATE_VALUE);
};
export var getLastDateOfCurrentYear = function() {
    return _format(endOfYear(new Date()), DEFAULT_DATE_VALUE);
};
export var getDateFormattedPlus = function(dateFormatted) {
    var nb = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 1;
    return _format(addDays(new Date(dateFormatted), nb), DEFAULT_DATE_VALUE);
};
export var getDateFormattedMinus = function(dateFormatted) {
    var nb = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 1;
    return _format(subDays(new Date(dateFormatted), nb), DEFAULT_DATE_VALUE);
};
// Use only this - if its date with time
export function parseFromUtc(serverDate) {
    return fromZonedTime(serverDate, "UTC");
}
// Use only this - if its only date
function isDateValid(dateStr) {
    // Check if dateStr is a falsy value like undefined, null, or an empty string
    if (!dateStr) return false;
    var date = new Date(dateStr);
    return isValid(date); // Ensures the date is valid
}
export function parseDateWithoutTime(inputDate) {
    var format = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : DEFAULT_DATE_VALUE, refDate = arguments.length > 2 ? arguments[2] : void 0;
    if (!isDateValid(inputDate)) return null;
    return parse(inputDate, format || DEFAULT_DATE_VALUE, refDate || new Date());
}
// Function to extract the date and time components from a UTC date
export function extractDateAndTimeFromDate(serverDate, isUtc) {
    var zonedDate;
    if (isUtc) {
        // Convert the server date (UTC) to the local time zone
        zonedDate = parseFromUtc(serverDate, "UTC");
    } else {
        zonedDate = serverDate;
    }
    var localDate = new Date(zonedDate);
    // Extract the time part (ignoring seconds)
    var timeComponent = _format(zonedDate, DATE_HOURS_MINUTES_FORMAT);
    // Format the local date back to the desired string format
    var dateComponent = _format(localDate, DEFAULT_DATE_VALUE);
    // Return both the formatted date string and the Date object without time
    return {
        localDate: dateComponent,
        localTime: timeComponent
    };
}
export function combineLocalDateAndTimeToUTC(date, time) {
    // Combining date and time into a single ISO string
    var combinedLocalDateTime = "".concat(date, "T").concat(time, ":00"); // Assuming seconds are always 00
    // Parse the combined date-time string as an ISO date
    var localDate = parseISO(combinedLocalDateTime);
    var utcDate = toZonedTime(localDate, "UTC");
    var formattedUtcDate = _format(utcDate, DEFAULT_DATE_VALUE_WITH_TIME_WITHOUT_SECONDS);
    return formattedUtcDate;
}
export function formatDateWithoutTime(param) {
    var inputDate = param.inputDate, _param_format = param.format, format = _param_format === void 0 ? DEFAULT_DATE_DISPLAY : _param_format, options = param.options, parseFormat = param.parseFormat, parseRefDate = param.parseRefDate;
    return _format(parseDateWithoutTime(inputDate, parseFormat, parseRefDate), format || DEFAULT_DATE_DISPLAY, options);
}
//Local storage wrappers
export function getLocalStorageItem(key) {
    if (!hasLocalStorageEnabled) return null;
    try {
        return window.localStorage.getItem(key);
    } catch (e) {
        setHasLocalStorageEnabled(false);
        return null;
    }
}
export function removeLocalStorageItem(key) {
    if (!hasLocalStorageEnabled) return null;
    try {
        return window.localStorage.removeItem(key);
    } catch (e) {
        setHasLocalStorageEnabled(false);
        return null;
    }
}
export function setLocalStorageItem(key, value) {
    if (!hasLocalStorageEnabled) return null;
    try {
        return window.localStorage.setItem(key, value);
    } catch (e) {
        setHasLocalStorageEnabled(false);
        return null;
    }
}
export function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for(var i = 0; i < binaryString.length; i++){
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}
export function handlePusherNavbarNotifications(param) {
    var dispatch = param.dispatch, myCompanyId = param.myCompanyId;
    var filters = [
        {
            field: NOTIFICATIONS_FILTER_IS_READ,
            operator: operators.EQ,
            value: false
        }
    ];
    dispatch(notificationsActions.getNotifications({
        myCompanyId: myCompanyId,
        page: 1,
        perPage: DEFAULT_NOTIFICATIONS_PER_PAGE,
        filters: filters
    })).catch(function(e) {
        return handleReportError(e);
    });
}
export function handleChangeFormikArrayField(param) {
    var field = param.field, formik = param.formik, index = param.index, values = param.values;
    formik.setFieldValue("".concat(field, "[").concat(index, "]"), values, false);
    if (formik.errors[field]) {
        var newErrors = _object_spread({}, formik.errors[field]);
        delete newErrors[index];
        if (Object.keys(newErrors).length > 0) {
            formik.setErrors(_object_spread_props(_object_spread({}, formik.errors), _define_property({}, field, newErrors)));
        } else {
            var _formik_errors = formik.errors, _ = _formik_errors[field], filteredErrors = _object_without_properties(_formik_errors, [
                field
            ].map(_to_property_key));
            formik.setErrors(filteredErrors);
        }
    }
}
export function processDeliveryAddress(param) {
    var translate = param.translate, street = param.street, zipCode = param.zipCode, city = param.city, district = param.district, countyName = param.countyName, countryName = param.countryName, partyName = param.partyName;
    return "".concat(partyName ? "".concat(partyName, " - ") : "").concat(street ? street + "," : "", " ").concat(zipCode ? zipCode + "," : "", " ").concat(city, ", ").concat(district ? translate("clients-invoices.create-edit.partner-delivery-address-selector.district ") + " " + district : translate("clients-invoices.create-edit.partner-delivery-address-selector.county ") + " " + countyName, ", ").concat(countryName);
}
export var DOCUMENTS_TYPES = {
    CLIENT_GOODS_DESPATCH_ADVICE: "client-goods-despatch-advice",
    GOODS_RECEIPT_NOTE: "goods-receipt-note",
    SUPPLIER_INVOICE: "supplier-invoice",
    CLIENT_INVOICE: "client-invoice",
    STOCK_RECEPTION_RECEIPT: "stock-reception-receipt",
    STOCK_CONSUMPTION_RECEIPT: "stock-consumption-receipt"
};
/**
 * Get internet connection status
 */ export var isOnline = true;
window.ononline = function() {
    return isOnline = true;
};
window.onoffline = function() {
    return isOnline = false;
};
export var ENSURE_LOADER_PERMISSISON_REDIRECT_ERROR_IDENTIFIER_MESSAGE = "Redirect Error to be handled in showModalAsync catch";
export var LOADER_FROM_SHOW_MODAL_ASYNC_STRING_IDENTIFIER = "This will navigate from showModalAsync catch, not throw redirect from ensurePermission";
export function ensureLoaderPermission(_param) {
    return _ensureLoaderPermission.apply(this, arguments);
}
function _ensureLoaderPermission() {
    _ensureLoaderPermission = _async_to_generator(function(_param) {
        var permission, accessDeniedRedirectionRoute, errorCallback, _errorCallbackTriggerStatuses, actionAllowedCallback, listingApiOptions, args // Spread args to capture the rest of the context
        , NO_PERMISSION_STATUS_CODE, NOT_FOUND_STATUS_CODE, defaultCbTriggers, hasLogs, dispatch, getState, request, params, otherParams, abortController, loaderProps, fromShowModalAsyncIdentifier, listingApiErrorCallback, _listingApiErrorCallbackTriggerStatuses, throwError, isModalLoader, isPageLoader, isListingApiLoader, _args_modalLoaderArgs, ref, ref1, ref2, ref3, ref4, errorCallbackTriggerStatuses, listingApiErrorCallbackTriggerStatuses, isFromShowModalAsync, getEmptyListNoPermissionResponse, throwOrRedirectOrUseErrCallbackOnError, selectedCompanyHasPermission, err, __errCb, __errCbTriggerStatuses;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    permission = _param.permission, accessDeniedRedirectionRoute = _param.accessDeniedRedirectionRoute, errorCallback = _param.errorCallback, _errorCallbackTriggerStatuses = _param.errorCallbackTriggerStatuses, actionAllowedCallback = _param.actionAllowedCallback, listingApiOptions = _param.listingApiOptions, args = _object_without_properties(_param, [
                        "permission",
                        "accessDeniedRedirectionRoute",
                        "errorCallback",
                        "errorCallbackTriggerStatuses",
                        "actionAllowedCallback",
                        "listingApiOptions"
                    ]);
                    NO_PERMISSION_STATUS_CODE = httpStatusCodes.NOT_AUTHORIZED;
                    NOT_FOUND_STATUS_CODE = httpStatusCodes.NOT_FOUND;
                    defaultCbTriggers = [
                        NO_PERMISSION_STATUS_CODE
                    ];
                    hasLogs = false;
                    throwError = function(errMsg) {
                        throw new Error("".concat(ensureLoaderPermission.name, " - ").concat(errMsg));
                    };
                    isModalLoader = !!args.modalLoaderArgs;
                    isPageLoader = !!(args.loaderContext || args.loaderParams);
                    isListingApiLoader = !!listingApiOptions;
                    //! Check which type of loader it is
                    if (isModalLoader) {
                        ;
                        ;
                        _args_modalLoaderArgs = args.modalLoaderArgs, otherParams = _object_without_properties(_args_modalLoaderArgs, [
                            "dispatch",
                            "getState"
                        ]), //* Type 1 loader (args object) - Used directly on 'loader' from a modal component - So everywhere it is exported it is 'ensured'
                        ref = _args_modalLoaderArgs, dispatch = ref.dispatch, getState = ref.getState, ref, _args_modalLoaderArgs;
                        ;
                        ref1 = otherParams, abortController = ref1.abortController, loaderProps = ref1.loaderProps, fromShowModalAsyncIdentifier = ref1.fromShowModalAsyncIdentifier, ref1;
                    } else if (isPageLoader) {
                        ;
                        //* Type 2 loader (loaderContext and loaderParams) - Used directly on 'loader' from a create/edit (or any page)
                        ref2 = args.loaderContext, dispatch = ref2.dispatch, getState = ref2.getState, ref2;
                        ;
                        ref3 = args.loaderParams, request = ref3.request, params = ref3.params, ref3;
                    } else if (isListingApiLoader) {
                        ;
                        ref4 = listingApiOptions, dispatch = ref4.dispatch, getState = ref4.getState, listingApiErrorCallback = ref4.listingApiErrorCallback, _listingApiErrorCallbackTriggerStatuses = ref4.listingApiErrorCallbackTriggerStatuses, ref4;
                    } else {
                        throwError("\n        Not matching modal or page loaders, check parameters.\n        [modalLoader accepts .modalLoaderArgs object, will call the callback with (dispatch, abortController, getState, loaderProps)] \n        [pageLoader accepts .loaderContext and .loaderParams objects, will call the callback with ({ request, params }, { dispatch, getState })]\n        [listingApiLoader accepts .listingApiOptions with {listingApiErrorCallback, getState,dispatch, listingApiErrorCallbackTriggerStatuses}, will call callback()  or in case of 403/404 errors: listingApiErrorCallback({status}), if no listingApiErrorCallback is given will return {items:[],count:0} instead of calling it\n    ");
                    }
                    errorCallbackTriggerStatuses = _errorCallbackTriggerStatuses || defaultCbTriggers;
                    listingApiErrorCallbackTriggerStatuses = _listingApiErrorCallbackTriggerStatuses || defaultCbTriggers;
                    isFromShowModalAsync = fromShowModalAsyncIdentifier === LOADER_FROM_SHOW_MODAL_ASYNC_STRING_IDENTIFIER;
                    if (!permission) {
                        throwError("Needs a permission to check if the company has it, received: ".concat(permission));
                    }
                    if (isListingApiLoader) {
                        if (!getState) {
                            throwError("Listing api loader - Missing getState from .listingApiOptions, listingApiLoader needs it");
                        }
                        if (errorCallback || _errorCallbackTriggerStatuses) {
                            throwError("Listing api loader - Received .errorCallback or .errorCallbackTriggerStatuses, use .listingApiOptions.listingApiErrorCallback instead");
                        }
                        if (!Array.isArray(listingApiErrorCallbackTriggerStatuses)) {
                            throwError("Listing api loader - listingApiErrorCallbackTriggerStatuses must be an array, you overridden it: ".concat(listingApiErrorCallbackTriggerStatuses));
                        }
                        if (_listingApiErrorCallbackTriggerStatuses && !dispatch) {
                            throwError("Listing api loader - Missing dispatch from .listingApiOptions, listingApiLoader needs it if there are other trigger statuses besides 403 and 404, received: ".concat(_listingApiErrorCallbackTriggerStatuses));
                        }
                    }
                    if (isModalLoader || isPageLoader) {
                        if (!dispatch || !getState) {
                            throwError("Modal and Page loaders - Missing dispatch or getState from loader context");
                        }
                        if (!errorCallback && !accessDeniedRedirectionRoute) {
                            throwError("Modal and Page loaders - Need either accessDeniedRedirectionRoute or errorCallback, received rRoute: ".concat(accessDeniedRedirectionRoute, ", errCb: ").concat(errorCallback));
                        }
                        if (!errorCallback && _errorCallbackTriggerStatuses) {
                            throwError("Modal and Page loaders - you gave. errorCallbackTriggerStatuses:".concat(_errorCallbackTriggerStatuses, " without giving an error callback: ").concat(errorCallback));
                        }
                        if (errorCallback && !Array.isArray(errorCallbackTriggerStatuses)) {
                            throwError("Modal and Page loaders - you gave an error callback: ".concat(errorCallback, ", but you overridden the errCbTriggerStatuses array: ").concat(errorCallbackTriggerStatuses));
                        }
                        if (errorCallback && accessDeniedRedirectionRoute) {
                            throwError("Modal and Page loaders - cant have both an errCb ".concat(errorCallback, ", and an route to redirect to ").concat(accessDeniedRedirectionRoute));
                        }
                        if (listingApiOptions) {
                            throwError("Modal and Page loaders - Received .listingApiOptions, use instead .errorCallback with or without errorCallbackTriggerStatuses instead");
                        }
                    }
                    hasLogs && console.log("".concat(ensureLoaderPermission.name, " - Arguments received:"), {
                        dispatch: dispatch,
                        getState: getState,
                        permission: permission,
                        accessDeniedRedirectionRoute: accessDeniedRedirectionRoute,
                        errorCallback: errorCallback,
                        isFromShowModalAsync: isFromShowModalAsync,
                        abortController: abortController,
                        listingApiOptions: listingApiOptions
                    });
                    getEmptyListNoPermissionResponse = function(status) {
                        return {
                            isForbidden: status === NO_PERMISSION_STATUS_CODE,
                            items: [],
                            count: 0
                        };
                    };
                    throwOrRedirectOrUseErrCallbackOnError = function(param) {
                        var error = param.error, _status = param.status;
                        var status = _status || (error === null || error === void 0 ? void 0 : error.status);
                        var throwTheRealErrorOrCustomError = function() {
                            if (error) {
                                throw error;
                            } else if (status) {
                                xthrow403err(status);
                            }
                        };
                        if (isListingApiLoader) {
                            if (listingApiErrorCallbackTriggerStatuses.includes(status)) {
                                //? If we have callback, call that (from listing api) else return default empty list
                                return listingApiErrorCallback ? listingApiErrorCallback({
                                    isForbidden: status === NO_PERMISSION_STATUS_CODE,
                                    status: status
                                }) : getEmptyListNoPermissionResponse(status);
                            }
                            throwTheRealErrorOrCustomError();
                        }
                        //! Important to check if its from showModalAsync, because ConfirmTransport rendered after a navigate would enter in the if below (even if the address bar would have the new url) and after the throw from if block we would remain on internal server error page, rendering the flashMessage and navigating to listing only if the user refreshes by hand, which we do not want
                        //* Check if already on the accessDeniedRedirectionRoute (cancelDeclaration from EtransportListingPage for example)
                        if (isModalLoader && isFromShowModalAsync && window.location.pathname === accessDeniedRedirectionRoute && !errorCallback) {
                            //? In this case the redirect would not happen and the loader would not disappear, so we throw the err and it will be catched in handleLoadError
                            throwTheRealErrorOrCustomError();
                        } else {
                            //? else render the usual err/success message and redirect if not already on the redirectToRoute
                            if (status === NO_PERMISSION_STATUS_CODE) {
                                dispatch(function(dispatch, getState, thunkOptions) {
                                    thunkOptions.flashMessenger.setErrorMessage({
                                        message: thunkOptions.i18n.t("common.errors.no-permissions")
                                    });
                                });
                            }
                            if (status === NOT_FOUND_STATUS_CODE) {
                                dispatch(function(dispatch, getState, thunkOptions) {
                                    thunkOptions.flashMessenger.setErrorMessage({
                                        message: thunkOptions.i18n.t("common.errors.not-found")
                                    });
                                });
                            }
                            if (errorCallback) {
                                if (errorCallbackTriggerStatuses.includes(status)) {
                                    //* You can give more statuses here that call the errCb
                                    //? On a page that does not have listing, we have nowhere to redirect to, we still want the flashMessage for 403, but we want to control what we return
                                    return errorCallback({
                                        isForbidden: status === NO_PERMISSION_STATUS_CODE,
                                        status: status
                                    });
                                } else {
                                    throwTheRealErrorOrCustomError();
                                }
                            } else {
                                if (isFromShowModalAsync) {
                                    var error1 = new Error(ENSURE_LOADER_PERMISSISON_REDIRECT_ERROR_IDENTIFIER_MESSAGE);
                                    error1.status = 302;
                                    error1.location = accessDeniedRedirectionRoute; // Add the redirection URL to the error so showModalAsync can navigate in .catch
                                    throw error1; // Throw the error with the redirect information
                                }
                                throw redirect(accessDeniedRedirectionRoute);
                            }
                        }
                    };
                    /**
     * Check if the user has relevant permission
     */ selectedCompanyHasPermission = authSelectors.hasPermission(getState())(permission);
                    if (permission && !selectedCompanyHasPermission) {
                        hasLogs && console.log("missing permission check from ensure loader perms");
                        return [
                            2,
                            throwOrRedirectOrUseErrCallbackOnError({
                                status: NO_PERMISSION_STATUS_CODE
                            })
                        ];
                    }
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        8,
                        ,
                        9
                    ]);
                    if (!isModalLoader) return [
                        3,
                        3
                    ];
                    return [
                        4,
                        actionAllowedCallback(dispatch, abortController, getState, loaderProps)
                    ];
                case 2:
                    return [
                        2,
                        _state.sent()
                    ];
                case 3:
                    if (!isPageLoader) return [
                        3,
                        5
                    ];
                    return [
                        4,
                        actionAllowedCallback({
                            request: request,
                            params: params
                        }, {
                            dispatch: dispatch,
                            getState: getState
                        })
                    ];
                case 4:
                    // return await actionAllowedCallback(args.loaderParams, args.loaderContext);
                    return [
                        2,
                        _state.sent()
                    ];
                case 5:
                    if (!isListingApiLoader) return [
                        3,
                        7
                    ];
                    return [
                        4,
                        actionAllowedCallback()
                    ];
                case 6:
                    return [
                        2,
                        _state.sent()
                    ];
                case 7:
                    return [
                        3,
                        9
                    ];
                case 8:
                    err = _state.sent();
                    hasLogs && console.log("ensure permission loader - catched error after calling actionAllowedCallback", err);
                    if (err.status === NO_PERMISSION_STATUS_CODE) {
                        //* 403 always redirects (if no errCallback is given) - Except listingApiLoader that always returns, others don't return anything
                        return [
                            2,
                            throwOrRedirectOrUseErrCallbackOnError({
                                error: err
                            })
                        ];
                    }
                    if (err.status === NOT_FOUND_STATUS_CODE) {
                        //* 404 always redirects (if no errCallback is given) - Except listingApiLoader that always returns, others don't return anything
                        return [
                            2,
                            throwOrRedirectOrUseErrCallbackOnError({
                                error: err
                            })
                        ];
                    }
                    __errCb = errorCallback ? errorCallback : (listingApiOptions === null || listingApiOptions === void 0 ? void 0 : listingApiOptions.listingApiErrorCallback) ? listingApiErrorCallback : function(param) {
                        var status = param.status;
                        return getEmptyListNoPermissionResponse(status);
                    };
                    __errCbTriggerStatuses = _errorCallbackTriggerStatuses ? errorCallbackTriggerStatuses : _listingApiErrorCallbackTriggerStatuses ? listingApiErrorCallbackTriggerStatuses : defaultCbTriggers;
                    if (__errCb && __errCbTriggerStatuses.includes(err.status)) {
                        //? Depending on which callback is given, render flashMessage and call the callback for cases where throwOrRedirectOrUseErrCallbackOnError is not called (so.. anything else other than 403 404)
                        dispatch(function(dispatch, getState, thunkOptions) {
                            thunkOptions.flashMessenger.setErrorMessage({
                                message: getErrorMessageTranslated({
                                    errors: mapErrorByHttpStatusCode(err),
                                    customErrors: {},
                                    translate: thunkOptions.i18n.t
                                })
                            });
                        });
                        return [
                            2,
                            __errCb({
                                isForbidden: err.status === NO_PERMISSION_STATUS_CODE,
                                status: err.status
                            })
                        ];
                    }
                    hasLogs && console.log("ensure permission loader", "throwing err anyway since its neither 404 nor 403 (or any other status from triggers)");
                    throw err; //? Other errors would remain on "InternalServerError" page if not handled (without flashMsg)
                case 9:
                    return [
                        2
                    ];
            }
        });
    });
    return _ensureLoaderPermission.apply(this, arguments);
}
//Testing 403 in loaders
export var xthrow403err = function() {
    var status = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 403;
    var error = new Error("Custom Error - xThrow - status: ".concat(status));
    error.status = status;
    // Create a response object and set its status
    error.response = {
        status: status
    };
    error.mesasge = "Custom Error - xThrow - status: ".concat(status);
    throw error;
};
export var createListenersFactory = function(param) {
    var listen = param.listen, events = param.events, defaultCallback = param.defaultCallback, _param_customCallbacks = param.customCallbacks, customCallbacks = _param_customCallbacks === void 0 ? {} : _param_customCallbacks, _param_channel = param.channel, channel = _param_channel === void 0 ? "tenants" : _param_channel;
    // Array to store unlisten functions
    var unlistenFns = events.map(function(event) {
        // Use the custom callback if available, otherwise fallback to the default callback
        var callback = customCallbacks[event] || defaultCallback;
        return listen(channel, event, callback);
    });
    // Return a function that will clean up all listeners
    return function() {
        unlistenFns.forEach(function(unlisten) {
            if (typeof unlisten === "function") {
                unlisten();
            }
        });
    };
};
export var delay = function(ms) {
    return new Promise(function(resolve) {
        return setTimeout(resolve, ms);
    });
};
export function onRemoveDocumentReference(param) {
    var document = param.document, lines = param.lines, _param_isFromInvoice = param.isFromInvoice, isFromInvoice = _param_isFromInvoice === void 0 ? false : _param_isFromInvoice;
    return lines.map(function(line) {
        if (line.allocatedDocumentLines.length === 0) {
            return line;
        }
        var documentLine = line.allocatedDocumentLines.find(function(allocation) {
            return allocation.documentId === document.id;
        });
        if (isFromInvoice) {
            line.quantityInvoicing = Big(line.quantityInvoicing || 0).minus((documentLine === null || documentLine === void 0 ? void 0 : documentLine.unallocatedInvoicingQuantity) || 0).toFixed(2);
            line.quantityPackaging = Big(line.quantityPackaging || 0).minus((documentLine === null || documentLine === void 0 ? void 0 : documentLine.unallocatedPackagingQuantity) || 0).toFixed(2);
        } else {
            line.invoicingQuantity = Big(line.invoicingQuantity || 0).minus((documentLine === null || documentLine === void 0 ? void 0 : documentLine.unallocatedInvoicingQuantity) || 0).toFixed(2);
            line.packagingQuantity = Big(line.packagingQuantity || 0).minus((documentLine === null || documentLine === void 0 ? void 0 : documentLine.unallocatedPackagingQuantity) || 0).toFixed(2);
            line.allocatedDocumentLines = line.allocatedDocumentLines.filter(function(allocation) {
                return allocation.documentId !== document.id;
            });
        }
        line.allocatedDocumentLines = line.allocatedDocumentLines.filter(function(allocation) {
            return allocation.documentId !== document.id;
        });
        return line;
    }).filter(function(line) {
        return line.invoicingQuantity > 0 || line.quantityInvoicing > 0;
    });
}
export function onDeleteLineWithDocumentReference(param) {
    var line = param.line, documentLines = param.documentLines, onRemoveDocumentReference = param.onRemoveDocumentReference, onDeleteLine = param.onDeleteLine;
    if (!line.allocatedDocumentLines || line.allocatedDocumentLines.length === 0) {
        onDeleteLine();
        return;
    }
    var documentsToRemove = [];
    line.allocatedDocumentLines.forEach(function(allocatedDocument) {
        if (documentLines.find(function(documentLine) {
            return documentLine.id !== line.id && documentLine.allocatedDocumentLines.find(function(item) {
                return item.documentId === allocatedDocument.documentId;
            });
        })) {
            return;
        }
        documentsToRemove.push(allocatedDocument.documentId);
    });
    onDeleteLine();
    onRemoveDocumentReference(documentsToRemove);
}
export function isTouchDevice() {
    return "ontouchstart" in window || window.matchMedia && window.matchMedia("(pointer: coarse)").matches;
}
export function generateGoodsDespatchAdvicesReference(param) {
    var goodsDespatchAdvicesReferences = param.goodsDespatchAdvicesReferences, goodsDespatchAdvice = param.goodsDespatchAdvice;
    if (!goodsDespatchAdvicesReferences) {
        return "".concat(goodsDespatchAdvice.seriesAndNumber);
    }
    if (goodsDespatchAdvicesReferences.toLowerCase().includes(goodsDespatchAdvice.seriesAndNumber.toLowerCase())) {
        return goodsDespatchAdvicesReferences;
    }
    return goodsDespatchAdvicesReferences + ", ".concat(goodsDespatchAdvice.seriesAndNumber);
}
export function updateGoodsDespatchAdvicesReference(param) {
    var goodsDespatchAdvicesReferences = param.goodsDespatchAdvicesReferences, goodsDespatchAdvice = param.goodsDespatchAdvice;
    if (!goodsDespatchAdvicesReferences) {
        return goodsDespatchAdvicesReferences;
    }
    if (goodsDespatchAdvicesReferences[goodsDespatchAdvicesReferences.indexOf(goodsDespatchAdvice.seriesAndNumber) - 2] === ",") {
        return goodsDespatchAdvicesReferences.replace(", ".concat(goodsDespatchAdvice.seriesAndNumber), "");
    }
    if (!goodsDespatchAdvicesReferences.includes(",")) {
        return goodsDespatchAdvicesReferences.replace(goodsDespatchAdvice.seriesAndNumber, "");
    }
    if (goodsDespatchAdvicesReferences.includes(",") && goodsDespatchAdvicesReferences.indexOf(goodsDespatchAdvice.seriesAndNumber) === 0) {
        return goodsDespatchAdvicesReferences.replace("".concat(goodsDespatchAdvice.seriesAndNumber, ", "), "");
    }
}
